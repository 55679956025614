import React, { useEffect, useState } from "react";
import apiHelper from "utils/api";
import { Stack } from "@mui/material";
import MDButton from "components/MDButton";
import CheckIcon from "@mui/icons-material/Check";
import MDTypography from "components/MDTypography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import useVehicle from "hooks/useVehicle";
import MdCustomInput from "components/MDCustomInput";
import ControlledStates from "layouts/onboarding/components/input";
import useHubTL from "hooks/useHubTL";

function EditRiderTL({ _id, prevhub, prevtl }) {
  const [hub, setHub] = useState(prevhub);
  const [tl, setTl] = useState(prevtl);
  const [loading, setLoading] = useState(false);
  const { vehicle, setVehicle, vehicles, fetching } = useVehicle();
  const { tls, hubs } = useHubTL(setHub, setTl);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await apiHelper.post("riders/update-rider", {
        rider: {
          _id,
          TL: tls.find((itl) => itl.HUB_TL_ID === tl)?._id,
          hub: hubs.find((tl) => tl.HUB_TL_ID === hub)?._id,
        },
      });
      alert(response.message);
    } catch (error) {
      console.error(error);
      alert(`Error ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: 20, flex: 1 }}>
      {fetching && (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      )}
      {!fetching && (
        <Stack direction={"column"} gap={2}>
          <Stack>
            <MDTypography variant="button" fontWeight="medium" color="text">
              Assign Vehicle
            </MDTypography>
            <ControlledStates options={vehicles} value={vehicle} setValue={setVehicle} />
          </Stack>
          <Stack>
            <MDTypography variant="button" fontWeight="medium" color="text">
              TL
            </MDTypography>

            <ControlledStates
              options={tls.map((hb) => `${hb?.HUB_TL_ID}`)}
              value={tl}
              setValue={setTl}
            />
          </Stack>
          <Stack>
            <MDTypography variant="button" fontWeight="medium" color="text">
              HUB
            </MDTypography>
            <ControlledStates
              options={hubs.map((hb) => `${hb?.HUB_TL_ID}`)}
              value={hub}
              setValue={setHub}
            />
          </Stack>
          <MDButton onClick={handleSubmit} color="success" variant="contained">
            {loading ? "Please wait..." : "UPDATE"}
          </MDButton>
        </Stack>
      )}
    </div>
  );
}

EditRiderTL.propTypes = { _id: PropTypes.any, prevhub: PropTypes.any, prevtl: PropTypes.any };

export default EditRiderTL;
