import apiHelper from "utils/api";

const { useEffect, useState } = require("react");

function useHubTL() {
  const [hubs, setHUBS] = useState([]);
  const [tls, setTLS] = useState([]);

  const [fetching, setFetching] = useState(false);
  const fetchVehicles = async () => {
    setFetching(true);
    try {
      await apiHelper.get(`admins/get-hub-tl`).then((data) => {
        console.log(data);
        setHUBS(data.hubs);
        setTLS(data.tls);
      });
    } catch (error) {
      setFetching(false);
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return { fetching, tls, hubs };
}

export default useHubTL;
