import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function data(onboardings, openDialog) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Rider Name", accessor: "name", width: "45%", align: "left" },
      { Header: "Info", accessor: "phone", align: "left" },
      { Header: "PAN", accessor: "pan", align: "left" },
      { Header: "DL", accessor: "dl", align: "left" },

      { Header: "Company", accessor: "Company", align: "left" },
      { Header: "Address", accessor: "address", align: "center" },
      { Header: "Aadhaar", accessor: "viewAadhaar", align: "center" },
      { Header: "View", accessor: "view", align: "center" },
    ],

    rows: onboardings.map((onboarding, index) => {
      return {
        name: <Author image={onboarding.photo} name={onboarding.NAME} email={onboarding.CID} />,
        phone: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Phone 1: {onboarding.CONTACT_1} <br />
            Phone 2 : {onboarding.CONTACT_2} <br />
            Email : {onboarding.EMAIL} <br />
          </MDTypography>
        ),

        Company: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {onboarding.Company} <br />
            {onboarding.Company_ID}
          </MDTypography>
        ),
        viewAadhaar: (
          <a href={onboarding.aadhaar} target="_blank" rel="noreferrer">
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href={onboarding.aadhaar}
                target="_blank"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                {onboarding.AADHAAR_CARD_NUMBER}
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </a>
        ),
        view: (
          <Link to={`/onboardings/${onboarding._id}`}>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                View
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </Link>
        ),
        address: (
          <MDTypography
            onClick={() => openDialog(onboarding, true, false)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {onboarding.Address}
          </MDTypography>
        ),
        pan: (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <MDTypography
              component="a"
              href={onboarding.pan}
              target="_blank"
              variant="caption"
              color="blue"
              fontWeight="medium"
            >
              {onboarding.GST_NO_PAN_CARD_NUMBER}
            </MDTypography>

            <ArrowForward marginLeft="10px" />
          </MDBox>
        ),
        dl: (
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <MDTypography
              component="a"
              href={onboarding.dl}
              target="_blank"
              variant="caption"
              color="blue"
              fontWeight="medium"
            >
              {onboarding.DRIVER_LICENSE_NUMBER}
            </MDTypography>

            <ArrowForward marginLeft="10px" />
          </MDBox>
        ),
      };
    }),
  };
}
