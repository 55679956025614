import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Vehicles from "layouts/vehicles/index";

// @mui icons
import Icon from "@mui/material/Icon";
import Riders from "layouts/riders";
import Admins from "layouts/admin";
import Onboardings from "layouts/onboardings";
import Reports from "layouts/reports";
import DocsMgmt from "layouts/docs_mgmt";
import Locations from "layouts/locations";
import Logs from "./layouts/logs";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "collapse",
    name: "Riders",
    key: "riders",
    icon: <Icon fontSize="small">directions_bike</Icon>,
    route: "/riders",
    component: <Riders />,
  },
  {
    type: "collapse",
    name: "Vehicles",
    key: "vehicles",
    icon: <Icon fontSize="small">electric_bike</Icon>,
    route: "/vehicles",
    component: <Vehicles />,
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">persons</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/admins",
    component: <Admins />,
  },
  {
    type: "collapse",
    name: "Onboardings",
    key: "onboardings",
    icon: <Icon fontSize="small">checklist</Icon>,
    route: "/onboardings",
    component: <Onboardings />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">report</Icon>,
    route: "/reports",
    component: <Reports />,
  },
  {
    type: "collapse",
    name: "Docs Mgmt.",
    key: "docs_management",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/docs_management",
    component: <DocsMgmt />,
  },
  {
    type: "collapse",
    name: "Locations",
    key: "locations",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/locations",
    component: <Locations />,
  },
  {
    type: "collapse",
    name: "Logs",
    key: "logs",
    icon: <Icon fontSize="small">dairy</Icon>,
    route: "/logs",
    component: <Logs />,
  },
];

export default routes;
