import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import team2 from "assets/images/team-2.jpg";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import PropTypes from "prop-types";

export default function data(vehicles, openDialog) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Vehicle", accessor: "vehicle", width: "45%", align: "left" },
      { Header: "Number Plate", accessor: "number_plate", align: "left" },
      { Header: "BRAND", accessor: "BRAND", align: "center" },
      { Header: "Color", accessor: "COLOR", align: "center" },
      { Header: "Location", accessor: "location", align: "center" },
      { Header: "Rider", accessor: "rider", align: "center" },
      { Header: "Details", accessor: "details", align: "center" },
      { Header: "Info", accessor: "info", align: "center" },
    ],

    rows: vehicles.map((vehicle, index) => {
      return {
        vehicle: <Author image={team2} name={vehicle.vehicle_id} email={vehicle.MODEL} />,
        number_plate: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {vehicle.Number_Plate}
          </MDTypography>
        ),
        COLOR: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {vehicle.COLOR}
          </MDTypography>
        ),

        BRAND: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {vehicle.BRAND}
          </MDTypography>
        ),

        location: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {vehicle.LOCATION?.HUB_TL_ID} <br />
            {vehicle.LOCATION?.name}
          </MDTypography>
        ),
        rider: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {vehicle.currentRider?.NAME} <br />
            {vehicle.currentRider?.CID}
          </MDTypography>
        ),

        details: (
          <MDTypography
            onClick={() => openDialog(vehicle, true, false)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </MDTypography>
        ),
        info: (
          <Link to={`/vehicles/${vehicle.vehicle_id}`}>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                View
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </Link>
        ),
      };
    }),
  };
}
