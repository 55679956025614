import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import team2 from "assets/images/team-2.jpg";
import { Link } from "react-router-dom";
import { ArrowForward, Edit } from "@mui/icons-material";

function role(id) {
  switch (id) {
    case "111A":
      return "Super";
      break;
    case "111":
      return "Admin";
      break;
    case "121":
      return "HUB";
      break;
    case "131":
      return "TL";
      break;
  }
}

export default function data(admins, openDialog) {
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  Author.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Admin", accessor: "admin", width: "45%", align: "left" },
      { Header: "Role", accessor: "role", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "HUB_TL_ID", accessor: "id", align: "center" },
      { Header: "Areas", accessor: "areas", align: "center" },
      { Header: "HUB Coordinates", accessor: "coordinates", align: "center" },
    ],

    rows: admins.map((admin, index) => {
      return {
        admin: <Author image={team2} name={admin.name} email={admin.email} />,
        role: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {role(admin.role)}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={admin?.isActive ? "Active" : "Blocked"}
              color={admin?.isActive ? "success" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),

        id: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {admin.HUB_TL_ID}
          </MDTypography>
        ),

        details: (
          <MDTypography
            onClick={() => openDialog(admin, true, false)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </MDTypography>
        ),
        info: (
          <Link to={`/admins/${admin.vehicle_id}`}>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                View
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </Link>
        ),
        coordinates: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Lon : {admin.coordinates?.lon}
            <br />
            Lat : {admin.coordinates?.lat}
          </MDTypography>
        ),
        areas: (
          <Link to={`/admins/editAdmin/${admin._id}`}>
            <MDBox
              style={{ cursor: "pointer" }}
              cursor="pointer"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                View
              </MDTypography>
              <MDBox width={5} />
              <Edit marginLeft="10px" />
            </MDBox>
          </Link>
        ),
      };
    }),
  };
}
