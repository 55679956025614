import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState, useEffect } from "react";
import { useMaterialUIController } from "context";
import { useParams } from "react-router-dom";
import { cardStyle } from "./styles/cardStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";

import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import apiHelper from "utils/api";
import {
  ArrowBack,
  CloseSharp,
  DownloadSharp,
  Person2Rounded,
  PersonPinSharp,
  PhoneAndroidRounded,
} from "@mui/icons-material";
import { saveAs } from "file-saver";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { GuarantorCard } from "layouts/rider";
import MDAvatar from "components/MDAvatar";
import { Dialog } from "@mui/material";
import Approve from "./components/approve";

export default function Onboarding() {
  const { id } = useParams();
  const [fetching, setFetching] = useState(true);
  const [rider, setRider] = useState();
  const [controller] = useMaterialUIController();
  const [open, setOpen] = useState(false);
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const downloadImage = (url, name) => {
    saveAs(url, name);
  };
  const fetchRider = async () => {
    setFetching(true);
    try {
      await apiHelper.get(`riders/get-onboardings?_id=${id}`).then((data) => {
        console.log(data.onboardings);
        setRider(data.onboardings);
        setFetching(false);
      });
    } catch (error) {
      setFetching(false);
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    fetchRider();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleApprove = async (data) => {
    try {
      setFetching(true);
      await apiHelper.post(`riders/approve-onboarding?_id=${id}`, { ...data }).then((data) => {
        setFetching(false);
        alert(` Success `);
        fetchRider();
      });
    } catch (error) {
      alert(error);
      setFetching(false);
      console.error("Error approving user:", error);
    }
  };

  const handleReject = async () => {
    try {
      setFetching(true);
      await apiHelper.post(`riders/reject-onboarding?_id=${id}`).then((data) => {
        setFetching(false);
        alert(` Success `);
        fetchRider();
      });
    } catch (error) {
      alert(error);
      setFetching(false);
      console.error("Error rejecting user:", error);
    }
  };
  return (
    <DashboardLayout fetching={fetching}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <Approve handleApprove={handleApprove} />
      </Dialog>
      <DashboardNavbar />
      {!fetching && (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 4 }}>
          <MDBox
            sx={(theme) =>
              cardStyle(theme, {
                active: true,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDAvatar src={rider.photo} name={rider.NAME} size="xl" />
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" color="white" fontWeight="medium">
                  {rider.NAME}
                </MDTypography>
                <MDTypography variant="caption" color="white">
                  {rider.EMAIL} <br /> {rider.CONTACT_1} <br />
                  {rider.Company}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 1, md: 12 }}
          >
            <Grid item xs={6}>
              <MDBox
                sx={(theme) =>
                  cardStyle(theme, {
                    active: true,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <GuarantorCard guarantor={rider.guarantor_1} number="1" />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox
                sx={(theme) =>
                  cardStyle(theme, {
                    active: true,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <GuarantorCard guarantor={rider.guarantor_2} number="2" />
              </MDBox>
            </Grid>
          </Grid>

          <Grid
            columns={{ xs: 1, md: 12 }}
            container
            marginTop={1}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <Item>Address : {rider.Address}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Company id : {rider.Company_ID}</Item>
            </Grid>

            <Grid item xs={6}>
              <Item>Company : {rider.Company}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                Contact : {rider.CONTACT_1} , {rider.CONTACT_2}
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>DOB : {rider.DATE_OF_BIRTH}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Aadhaar : {rider.AADHAAR_CARD_NUMBER}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>GST/PAN : {rider.GST_NO_PAN_CARD_NUMBER}</Item>
            </Grid>

            <Grid item xs={6}>
              <Item>
                <MDButton onClick={() => downloadImage(rider.dl, "hi.jpg")} variant="contained">
                  {" "}
                  <DownloadSharp /> DL Photo
                </MDButton>

                <MDButton onClick={() => downloadImage(rider.photo, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Photo
                </MDButton>
                <MDButton onClick={() => downloadImage(rider.aadhaar, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Front Side Aadhaar
                </MDButton>
                <MDButton onClick={() => downloadImage(rider.aadhaarBack, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Back Side Aadhaar
                </MDButton>
                <MDButton onClick={() => downloadImage(rider.pan, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Rider PAN
                </MDButton>
              </Item>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2}>
            <MDButton
              disabled={rider.isRejected || rider.isApprove}
              onClick={handleReject}
              color="error"
              variant="outlined"
              startIcon={<CloseSharp />}
            >
              {rider.isRejected ? "Rejected" : "Reject"}
            </MDButton>
            <MDButton
              onClick={handleOpen}
              color="success"
              variant="contained"
              endIcon={<SendIcon />}
              disabled={rider.isApprove}
            >
              {rider.isApprove ? "Approved" : "Approve"}
            </MDButton>
          </Stack>
        </div>
      )}
    </DashboardLayout>
  );
}
