import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import apiHelper from "utils/api";
import PropTypes from "prop-types";

function EditVehicle({ vehicle }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...vehicle });

  const handleFieldChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await apiHelper.post("vehicles/update-vehicle", {
        vehicle: formData,
      });
      alert(response.message);
      setFormData({ ...response.updatedData });
    } catch (error) {
      console.error(error);
      alert(`Error ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: 10, gap: 10, display: "grid", gridColumn: 2, gridRow: 2 }}>
      {Object.keys(vehicle).map((key) => (
        <TextField
          key={key}
          label={key}
          variant="outlined"
          value={formData[key] || ""}
          onChange={(e) => handleFieldChange(key, e.target.value)}
        />
      ))}

      <Button variant="contained" disabled={loading} color="primary" onClick={handleSubmit}>
        {loading ? "Updating..." : "Save Changes"}
      </Button>
    </div>
  );
}

EditVehicle.propTypes = {
  vehicle: PropTypes.any,
};

export default EditVehicle;
