import React, { useEffect, useState } from "react";
import ControlledStates from "./input";
import apiHelper from "utils/api";
import { Stack } from "@mui/material";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import MDButton from "components/MDButton";
import CheckIcon from "@mui/icons-material/Check";
import MDTypography from "components/MDTypography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import useVehicle from "hooks/useVehicle";

const Input = React.forwardRef(function CustomInput(props, ref) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

function Approve({ handleApprove }) {
  const { vehicle, setVehicle, vehicles, fetching } = useVehicle();
  const [cid, setCid] = useState();
  const [area, setArea] = useState();
  useEffect(() => {}, []);
  return (
    <div style={{ padding: 20, flex: 1 }}>
      {fetching && (
        <Box sx={{ width: 300 }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      )}
      {!fetching && (
        <Stack direction={"column"} gap={2}>
          <Stack>
            <MDTypography variant="button" fontWeight="medium" color="text">
              Assign Vehicle
            </MDTypography>
            <ControlledStates options={vehicles} value={vehicle} setValue={setVehicle} />
          </Stack>
          <Stack>
            <MDTypography variant="button" fontWeight="medium" color="text">
              Assign User ID
            </MDTypography>

            <Input
              value={cid}
              onChange={(e) => setCid(e.target.value)}
              aria-label="Demo input"
              placeholder="Type something…"
            />
          </Stack>
          <Stack>
            <MDTypography variant="button" fontWeight="medium" color="text">
              Operational Area
            </MDTypography>

            <Input
              value={area}
              onChange={(e) => setArea(e.target.value)}
              aria-label="Operational Area"
              placeholder="Operational Area"
            />
          </Stack>
          <MDButton
            onClick={() =>
              handleApprove({ vehicle_id: vehicle, cid, time: Date.now(), operationalArea: area })
            }
            color="success"
            variant="contained"
            endIcon={<CheckIcon />}
          >
            Approve
          </MDButton>
        </Stack>
      )}
    </div>
  );
}

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const InputElement = styled("input")(
  ({ theme }) => `
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

Approve.propTypes = {
  handleApprove: PropTypes.Any,
};

export default Approve;
