export const VEHICLE_CHUNK_DATA = {
  vehicel_id: "ETMPSV-002",
  MODEL: "Jetter Pro",
  CHASSIS_NO_VNI: "na",
  MOTOR_NO: "na",
  MOTOR_POWER: "1200W",
  Charger_Number: "na",
  Number_Plate: "ETMPSV-002",
  Master_Doc_ID_CONTROLER_NO: "NA",
  Policy_no_Insurance_No: "NA",
  COLOR: "SILVER",
  BRAND: "TNR",
  BATTERY_TYPE: "LEAD ACID",
  BATTERY_CONFIGURATION: "72V / 36AH",
  BATTERY_NO_1: "",
  BATTERY_NO_2: "",
  LAST_SERVICE_DATE: "",
  LAST_CHECK_UP_DATE: "",
  KEY_FOB_NO: "-",
  fleet_no: "",
  vehicel_type: "Slow speed",
  LOCATION: "PPTA",
  IMEI_NO: "",
  SIM_NO: "",
  GST_NO: "10BADPN3276R1ZE",
  Accessories: "2 key, 2remot, gps, charger",
  RC: "",
};

export const RIDERS_CHUNK_DATA = {
  CID: "",
  PHOTO: "",
  NAME: "",
  EMAIL: "EMAIL",
  CONTACT_1: "CONTACT 1",
  CONTACT_2: "CONTACT 2",
  DATE_OF_BIRTH: "DATE OF BIRTH",
  Company: "Company",
  Company_ID: "Company ID",
  Address: "Temprory Address",
  DRIVER_LICENSE_NUMBER: "DRIVER LICENSE NUMBER",
  DATE_OF_EXPIRATION: "DATE OF EXPIRATION",
  AADHAAR_CARD_NUMBER: "AADHAAR CARD NUMBER",
  GST_NO_PAN_CARD_NUMBER: "GST NO / PAN CARD NUMBER",
  status: "",
  Joining_Date: "",
  returning_date: "returning date",
  last_payment_date: "last payment date",
  AGREEMENT_PHOTO: "AGREEMENT PHOTO",
  under_tip: "under tip",
  SORCE_BY: "SORCE BY",
};
