import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";

// Images
import team2 from "assets/images/team-2.jpg";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import apiHelper from "utils/api";
import { Dropdown } from "@mui/base";
import MDSelect from "components/MDSelect";
import { useEffect, useState } from "react";

export default function data(riders, openDialog, openTLEditDialog, setQuery, companies = "") {
  const [statusFilter, setStatusFilter] = useState("All");
  console.log(companies);
  const companiesArray = companies?.split(",");
  const companyValues = companiesArray.map((company, index) => ({
    title: company,
    value: company,
  }));
  useEffect(() => {
    if (statusFilter === "All") {
      setQuery((info) => ({
        ...info,
        query: info.query?.replace(/&status=[^&]*/, ""),
      }));
      return;
    }
    setQuery((info) => ({
      ...info,
      query: `${info.query?.replace(/&status=[^&]*/, "")}&status=${statusFilter}`,
    }));
  }, [statusFilter]);

  const pushFCM = async (rider) => {
    try {
      // setLoading(true);
      const response = await apiHelper.post("admin/riders/hit-fcm", {
        userId: rider,
      });
      // alert(response.message);
      // setFormData({ ...response.updatedData });
      // setTrigger(!trigger);
    } catch (error) {
      console.error(error);
      alert(`Error ${error}`);
    } finally {
      // setLoading(false);
    }
  };
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        Winning : {title}
      </MDTypography>
      <MDTypography variant="caption">Deposit : {description}</MDTypography>
    </MDBox>
  );

  Job.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Rider Name", accessor: "name", width: "45%", align: "left" },
      { Header: "Contact", accessor: "phone", align: "left" },
      {
        Header: (
          <MDSelect
            options={[
              { title: "Status", value: "All" },
              { title: "Active", value: "active" },
              { title: "Inactive", value: "inactive" },
            ]}
            setValue={setStatusFilter}
            minWidth="120px"
            value={statusFilter}
            multiple={false}
          ></MDSelect>
        ),
        accessor: "status",
        align: "center",
      },
      {
        Header: (
          <MDSelect
            options={[{ title: "Company", value: "All" }, ...companyValues]}
            setValue={setStatusFilter}
            value={["Company"]}
            minWidth="120px"
            multiple={true}
          ></MDSelect>
        ),
        accessor: "Company",
        align: "center",
      },
      { Header: "HUB", accessor: "Hub", align: "center" },
      { Header: "TL", accessor: "Tl", align: "center" },
      { Header: "FCM", accessor: "fcm", align: "center" },

      { Header: "EMAIL", accessor: "EMAIL", align: "center" },
      { Header: "Edit Details", accessor: "details", align: "center" },
      { Header: "View", accessor: "view", align: "center" },
    ],

    rows: riders.map((rider, index) => {
      return {
        name: <Author image={rider.PHOTO} name={rider.NAME} email={rider.CID} />,
        phone: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Phone 1: {rider.CONTACT_1} <br />
            Phone 2 : {rider.CONTACT_2}
          </MDTypography>
        ),
        EMAIL: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {rider.EMAIL}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={rider.status}
              color={rider?.status?.toLowerCase() === "active" ? "success" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),

        Company: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {rider.Company}
          </MDTypography>
        ),

        details: (
          <MDTypography
            onClick={() => openDialog(rider, true, false)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </MDTypography>
        ),
        Hub: (
          <MDTypography
            onClick={() => openTLEditDialog(rider)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {rider.hub?.name} ( {rider?.hub?.HUB_TL_ID} )
          </MDTypography>
        ),
        Tl: (
          <MDTypography
            onClick={() => openTLEditDialog(rider)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {rider.TL?.name} ( {rider.TL?.HUB_TL_ID} )
          </MDTypography>
        ),
        fcm: (
          <Link onClick={() => pushFCM(rider._id)}>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                PUSH
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </Link>
        ),
        view: (
          <Link to={`/riders/${rider.CID}`}>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                View
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </Link>
        ),
      };
    }),
  };
}
