import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";

function DocsMgmt() {
  const [riders, setriders] = useState([]);
  const [rider, setRider] = useState();

  return (
    <DashboardLayout>
      <DashboardNavbar />
    </DashboardLayout>
  );
}

export default DocsMgmt;
