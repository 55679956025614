import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useFilePicker } from "use-file-picker";

// Data
import usersTableData from "layouts/vehicles/components/vehiclesTable";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDPagination from "components/MDPagination";
import apiHelper from "utils/api";
import { useEffect, useState } from "react";
import Editvehicle from "./components/EditVehicle";
import MDButton from "components/MDButton";
import QueryBuilder from "layouts/queryBuilder";
import { VEHICLE_CHUNK_DATA } from "utils/chunks";
function Vehicles() {
  const [vehicles, setVehicles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [isQuery, setIsQuery] = useState();
  const [isList, setIsList] = useState();
  const [isHistory, setIsHistory] = useState();
  const [searchField, setSearchField] = useState();
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    totalVehicles: 0,
    totalPages: 0,
    query: undefined,
  });
  useEffect(() => {
    const fetchVehicles = async () => {
      setFetching(true);
      try {
        await apiHelper
          .get(
            `vehicles/get-vehicles?page=${pageInfo.page}&query=${encodeURIComponent(
              pageInfo.query
            )}`
          )
          .then((data) => {
            console.log(data.vehicles);
            setVehicles(data.vehicles);

            setPageInfo((info) => ({ ...info, ...data.pagination }));
            setFetching(false);
          });
      } catch (error) {
        setFetching(false);

        console.error("Error fetching users:", error);
      }
    };

    fetchVehicles();
  }, [pageInfo.page, pageInfo.query]);

  const handleClickOpen = (vehicle) => {
    setIsQuery(false);
    setVehicle(vehicle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleQuerySubmit = (queries) => {
    const queryString = queries.map((query) => `${query.field}=${query.value}`).join("&");
    setPageInfo((info) => ({ ...info, query: queryString }));
  };
  const { openFilePicker, filesContent, loading } = useFilePicker({
    accept: ".csv",
    multiple: false,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      handleBulkImport(plainFiles[0]);
    },
  });
  const handleBulkImport = async (file) => {
    try {
      setFetching(true);
      const response = await apiHelper.upload("vehicles/import", file);
      setFetching(false);
      alert(response.message);
    } catch (error) {
      setFetching(false);
      console.error("Error uploading file:", error);
      alert(error);
    }
  };
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { columns, rows } = usersTableData(vehicles, handleClickOpen);
  const { columns: pColumns, rows: pRows } = projectsTableData();

  return (
    <DashboardLayout fetching={fetching}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isQuery ? "Query Builder For Vehicles" : "View/Edit Vehicle"}
        </DialogTitle>
        {isQuery ? (
          <QueryBuilder
            chunk={VEHICLE_CHUNK_DATA}
            handleQuerySubmit={handleQuerySubmit}
          ></QueryBuilder>
        ) : (
          <Editvehicle vehicle={vehicle} />
        )}
      </Dialog>
      <DashboardNavbar setTextField={setSearchField} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                justifyContent="space-between"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Vehicles List
                </MDTypography>
                <Stack direction="row" spacing={2}>
                  <MDButton
                    onClick={() => {
                      setIsQuery(true);
                      setOpen(true);
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>query_builder</Icon>
                    &nbsp;Query Builder
                  </MDButton>

                  <MDButton
                    onClick={() => {
                      openFilePicker();
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>upload</Icon>
                    &nbsp;Bulk Upload
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Pagination
          onChange={(e, value) => setPageInfo((prev) => ({ ...prev, page: value }))}
          count={pageInfo.totalPages}
          page={pageInfo.page}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Vehicles;
