import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";

// Images
import team2 from "assets/images/team-2.jpg";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { formatTimestamp } from "layouts/locations/components/userTable";

export default function data(logs, openDialog, openTLEditDialog) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        Winning : {title}
      </MDTypography>
      <MDTypography variant="caption">Deposit : {description}</MDTypography>
    </MDBox>
  );

  Job.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Rider Name", accessor: "name", width: "45%", align: "left" },
      { Header: "Contact", accessor: "phone", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Company", accessor: "Company", align: "center" },
      { Header: "Timestamp", accessor: "timestamp", align: "center" },
      { Header: "View", accessor: "view", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
      { Header: "Action By", accessor: "actionBy", align: "center" },
    ],

    rows: logs.map((log, index) => {
      return {
        name: <Author image={log.rider.PHOTO} name={log.rider.NAME} email={log.rider.CID} />,
        phone: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Phone 1: {log.rider.CONTACT_1} <br />
            Phone 2 : {log.rider.CONTACT_2}
          </MDTypography>
        ),
        EMAIL: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {log.EMAIL}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={log.rider.status}
              color={log?.rider.status?.toLowerCase() === "active" ? "success" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {log.action}
          </MDTypography>
        ),
        actionBy: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {log.admin?.name} <br />
            {log?.admin.HUB_TL_ID}
          </MDTypography>
        ),
        timestamp: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formatTimestamp(parseInt(log.timeStamp))}
          </MDTypography>
        ),

        Company: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {log.rider?.Company}
          </MDTypography>
        ),

        details: (
          <MDTypography
            onClick={() => openDialog(log, true, false)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </MDTypography>
        ),
        Hub: (
          <MDTypography
            onClick={() => openTLEditDialog(log)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {log.hub?.name} ( {log?.hub?.HUB_TL_ID} )
          </MDTypography>
        ),
        Tl: (
          <MDTypography
            onClick={() => openTLEditDialog(log)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {log.TL?.name} ( {log.TL?.HUB_TL_ID} )
          </MDTypography>
        ),
        view: (
          <Link to={`/logs/${log.CID}`}>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                View
              </MDTypography>

              <ArrowForward marginLeft="10px" />
            </MDBox>
          </Link>
        ),
      };
    }),
  };
}
