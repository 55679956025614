import {
  ArrowBack,
  DownloadSharp,
  Person2Rounded,
  PersonPinSharp,
  PhoneAndroidRounded,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiHelper from "utils/api";
import { useMaterialUIController } from "context";
import { cardStyle } from "layouts/rider/styles/cardStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import usersTableData from "layouts/vehicle/components/vehicleRiderLogTable";

function Vehicle() {
  const { id } = useParams();
  const [fetching, setFetching] = useState(true);
  const [riders, setRiders] = useState([]);
  const [vehicle, setVehicle] = useState();
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    const fetchVehicle = async () => {
      setFetching(true);
      try {
        await apiHelper
          .get(`vehicles/get-vehicles?page=1&query=${encodeURIComponent(`vehicle_id=${id}`)}`)
          .then((data) => {
            console.log(data.vehicles);
            setVehicle(data.vehicles[0]);
            setRiders(data.vehicles[0].riders);
            setFetching(false);
          });
      } catch (error) {
        setFetching(false);
        console.error("Error fetching users:", error);
      }
    };

    fetchVehicle();
  }, []);
  const { columns, rows } = usersTableData(riders, null);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {!fetching && (
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      )}
    </DashboardLayout>
  );
}

export default Vehicle;
