import {
  ArrowBack,
  DownloadSharp,
  Person2Rounded,
  PersonPinSharp,
  PhoneAndroidRounded,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiHelper from "utils/api";
import { useMaterialUIController } from "context";
import { cardStyle } from "./styles/cardStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";

function Rider({ match }) {
  const { id } = useParams();
  const [fetching, setFetching] = useState(true);
  const [rider, setRider] = useState();
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    const fetchRider = async () => {
      setFetching(true);
      try {
        await apiHelper
          .get(`riders/get-riders?page=1&query=${encodeURIComponent(`CID=${id}`)}`)
          .then((data) => {
            console.log(data.riders);
            setRider(data.riders[0]);

            setFetching(false);
          });
      } catch (error) {
        setFetching(false);
        console.error("Error fetching users:", error);
      }
    };

    fetchRider();
  }, []);
  const downloadImage = (url, name) => {
    saveAs(url, name);
  };

  return (
    <DashboardLayout fetching={fetching}>
      <DashboardNavbar />
      {!fetching && (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 4 }}>
          <MDBox
            sx={(theme) =>
              cardStyle(theme, {
                active: true,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDAvatar src={rider.PHOTO} name={rider.NAME} size="xl" />
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" color="white" fontWeight="medium">
                  {rider.NAME}
                </MDTypography>
                <MDTypography variant="caption" color="white">
                  {rider.EMAIL} <br /> {rider.CONTACT_1} <br />
                  {rider.Company}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 1, md: 12 }}
          >
            <Grid item xs={6}>
              <MDBox
                sx={(theme) =>
                  cardStyle(theme, {
                    active: true,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <GuarantorCard guarantor={rider.guarantor_1} number="1" />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox
                sx={(theme) =>
                  cardStyle(theme, {
                    active: true,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <GuarantorCard guarantor={rider.guarantor_2} number="2" />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox
                sx={(theme) =>
                  cardStyle(theme, {
                    active: true,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <GuarantorCard guarantor={rider.guarantor_3} number="3" />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox
                sx={(theme) =>
                  cardStyle(theme, {
                    active: true,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <GuarantorCard guarantor={rider.guarantor_4} number="4" />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox
            sx={(theme) =>
              cardStyle(theme, {
                active: true,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <Link to={`/vehicles/${rider.vehicle.vehicle_id}`}>
              <Grid container columnSpacing={5} columns={{ xs: 1, md: 2 }}>
                {Object.keys(rider.vehicle).map((key) => {
                  return key != "_id" ? (
                    <Grid item>
                      <MDTypography color="white">
                        {key} : {rider.vehicle[key]}
                      </MDTypography>
                    </Grid>
                  ) : (
                    <div></div>
                  );
                })}
              </Grid>
            </Link>
          </MDBox>

          <Grid
            columns={{ xs: 1, md: 12 }}
            container
            marginTop={1}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <Item>Address : {rider.Address}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Company id : {rider.Company_ID}</Item>
            </Grid>

            <Grid item xs={6}>
              <Item>Company : {rider.Company}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                Contact : {rider.CONTACT_1} , {rider.CONTACT_2}
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>DOB : {rider.DATE_OF_BIRTH}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Aadhaar : {rider.AADHAAR_CARD_NUMBER}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>GST/PAN : {rider.GST_NO_PAN_CARD_NUMBER}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>Joining date : {rider.Joining_Date}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <MDButton variant="contained">
                  {" "}
                  <DownloadSharp /> Aggreement Photo
                </MDButton>

                <MDButton>
                  {" "}
                  <DownloadSharp />
                  Rider Photo
                </MDButton>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <MDButton onClick={() => downloadImage(rider.dl, "hi.jpg")} variant="contained">
                  {" "}
                  <DownloadSharp /> DL Photo
                </MDButton>

                <MDButton onClick={() => downloadImage(rider.PHOTO, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Rider Photo
                </MDButton>
                <MDButton onClick={() => downloadImage(rider.aadhaar, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Rider Aadhaar
                </MDButton>
                <MDButton onClick={() => downloadImage(rider.pan, "hi.jpg")}>
                  {" "}
                  <DownloadSharp />
                  Rider PAN
                </MDButton>
              </Item>
            </Grid>
          </Grid>
        </div>
      )}
    </DashboardLayout>
  );
}

export const GuarantorCard = ({ guarantor, number }) => {
  return (
    <div>
      <MDTypography color="blue" fontWeight="regular">
        Guarantor : {number}
      </MDTypography>
      <MDTypography fontSize="15px" color="white" fontWeight="medium">
        <Person2Rounded /> {guarantor?.name}
      </MDTypography>
      <MDTypography fontSize="15px" color="white">
        {" "}
        <PhoneAndroidRounded /> Mobile : {guarantor?.mobile}
      </MDTypography>

      <MDTypography fontSize="15px" color="white">
        {" "}
        <PersonPinSharp /> Relation : {guarantor?.relation}
      </MDTypography>
    </div>
  );
};

GuarantorCard.propTypes = {
  guarantor: PropTypes.any,
  number: PropTypes.any,
};
Rider.propTypes = {
  match: PropTypes.any,
};

export default Rider;
