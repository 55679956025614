import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";

import { format, parseISO, differenceInMilliseconds } from "date-fns";

// Images
import team2 from "assets/images/team-2.jpg";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

export default function data(reports, openDialog) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        Winning : {title}
      </MDTypography>
      <MDTypography variant="caption">Deposit : {description}</MDTypography>
    </MDBox>
  );

  Job.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Rider", accessor: "rider", width: "45%", align: "left" },
      { Header: "Date", accessor: "date", align: "center" },
      { Header: "Punch In", accessor: "punchIn", align: "center" },
      { Header: "Punch Out", accessor: "punchOut", align: "center" },
      { Header: "Cash Collected", accessor: "collection", align: "center" },
      { Header: "Cash Deposited", accessor: "deposit", align: "center" },
      { Header: "Total Orders", accessor: "orders", align: "center" },
      { Header: "Duration", accessor: "duration", align: "center" },
      //   { Header: "More Info", accessor: "info", align: "center" },
    ],

    rows: reports.map((report, index) => {
      return {
        rider: (
          <Author
            image={report.rider?.PHOTO}
            name={report.rider?.NAME}
            email={report.rider?.EMAIL}
          />
        ),
        date: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {report.date}
          </MDTypography>
        ),
        orders: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {report?.orders ? report?.orders : "N/A"}
          </MDTypography>
        ),
        collection: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {report?.collection ? report?.collection : "N/A"}
          </MDTypography>
        ),
        deposit: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {report?.deposit ? report?.deposit : "N/A"}
          </MDTypography>
        ),

        punchIn: (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q=${report.punchInCoordinates.lat},${report.punchInCoordinates.lon}`,
                "_blank"
              );
            }}
          >
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                {report.punchedIn ? formatLocalTime(report.punchInTime) : "Not Punched in"}
              </MDTypography>
              <ArrowForward marginLeft="10px" />
            </MDBox>
          </span>
        ),
        punchOut: (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q=${report.punchOutCoordinates.lat},${report.punchOutCoordinates.lon}`,
                "_blank"
              );
            }}
          >
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                {report.punchedOut ? formatLocalTime(report.punchOutTime) : "Not Punched out"}
              </MDTypography>
              <ArrowForward marginLeft="10px" />
            </MDBox>
          </span>
        ),
        duration: (
          <MDTypography
            onClick={() => openDialog(report, true, false)}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            View
          </MDTypography>
        ),
        duration: (
          <MDTypography component="a" href="#" variant="caption" color="blue" fontWeight="medium">
            {calculateDuration(report.punchInTime, report.punchOutTime)}
          </MDTypography>
        ),
      };
    }),
  };
}
export const formatLocalTime = (utcTime) => {
  if (!utcTime) return "Not Punched in/out";
  return format(parseISO(utcTime), "yyyy-MM-dd HH:mm:ss");
};
export const calculateDuration = (punchInTime, punchOutTime) => {
  if (!punchInTime || !punchOutTime) return "N/A";
  const start = parseISO(punchInTime);
  const end = parseISO(punchOutTime);
  const durationInMilliseconds = differenceInMilliseconds(end, start);
  const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return `${hours} hours ${minutes} minutes`;
};
