import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { DotLoader } from "react-spinners";
import apiHelper from "utils/api";

function Basic() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState("111");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  // const [rememberMe, set RememberMe] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async () => {
    if (!email) {
      setMessage("Please Enter Email!");
    } else if (!password) setMessage("Please Enter Password");

    if (email && password) {
      setMessage("");
      setLoading(true);
      try {
        const data = { email, password, role };
        const response = await apiHelper.post("admins/login", data);
        console.log(response.message);
        console.log(response.token);
        const token = response.token;
        localStorage.setItem("user", token);
        window.location.reload();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setMessage(error.message);
        setLoading(false);
      }
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                label="Password"
                fullWidth
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="111"
              name="radio-buttons-group"
              onChange={(e) => setRole(e.target.value)}
            >
              <FormControlLabel value="111" control={<Radio />} label="Admin" />
              <FormControlLabel value="111A" control={<Radio />} label="Super" />
            </RadioGroup>
            {message != "" && <MDTypography color="error">{message}</MDTypography>}
            <MDBox mt={2} mb={1}>
              <MDButton onClick={handleLogin} variant="gradient" color="info" fullWidth>
                {loading ? <DotLoader size={20} loading color="white" /> : "sign in"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              {/* <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography> */}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
