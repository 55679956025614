import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import apiHelper from "utils/api";
import PropTypes from "prop-types";

function EditRider({ rider, trigger, setTrigger }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...rider });

  const handleFieldChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await apiHelper.post("riders/update-rider", {
        rider: formData,
      });
      alert(response.message);
      setFormData({ ...response.updatedData });
      setTrigger(!trigger);
    } catch (error) {
      console.error(error);
      alert(`Error ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: 10, gap: 10, display: "grid", gridColumn: 2, gridRow: 2 }}>
      <TextField
        key={"CID"}
        label={"CID"}
        variant="outlined"
        value={formData["CID"] || ""}
        onChange={(e) => handleFieldChange("CID", e.target.value)}
      />
      <TextField
        key={"NAME"}
        label={"Name"}
        variant="outlined"
        type="text"
        value={formData["NAME"] || ""}
        onChange={(e) => handleFieldChange("NAME", e.target.value)}
      />
      <TextField
        key={"CONATCT_1"}
        label={"Contact 1"}
        variant="outlined"
        type="number"
        value={formData["CONTACT_1"] || ""}
        onChange={(e) => handleFieldChange("CONTACT_1", e.target.value)}
      />
      <TextField
        key={"CONATCT_2"}
        label={"Contact 2"}
        variant="outlined"
        type="number"
        value={formData["CONTACT_2"] || ""}
        onChange={(e) => handleFieldChange("CONTACT_2", e.target.value)}
      />
      <TextField
        key={"EMAIL"}
        label={"Email"}
        variant="outlined"
        type="email"
        value={formData["EMAIL"] || ""}
        onChange={(e) => handleFieldChange("EMAIL", e.target.value)}
      />
      <TextField
        key={"DOB"}
        label={"DOB"}
        variant="outlined"
        value={formData["DATE_OF_BIRTH"] || ""}
        onChange={(e) => handleFieldChange("DATE_OF_BIRTH", e.target.value)}
      />
      <TextField
        key={"COMPANY"}
        label={"Company"}
        variant="outlined"
        type="text"
        value={formData["Company"] || ""}
        onChange={(e) => handleFieldChange("Company", e.target.value)}
      />
      <TextField
        key={"Company_id"}
        label={"Company id"}
        variant="outlined"
        type="text"
        value={formData["Company_ID"] || ""}
        onChange={(e) => handleFieldChange("Company_ID", e.target.value)}
      />
      <TextField
        key={"Aadhaar"}
        label={"Aadhaar Number"}
        variant="outlined"
        type="text"
        value={formData["AADHAAR_CARD_NUMBER"] || ""}
        onChange={(e) => handleFieldChange("AADHAAR_CARD_NUMBER", e.target.value)}
      />
      <TextField
        key={"PAN/GST"}
        label={"PAN/GST"}
        variant="outlined"
        type="text"
        value={formData["GST_NO_PAN_CARD_NUMBER"] || ""}
        onChange={(e) => handleFieldChange("GST_NO_PAN_CARD_NUMBER", e.target.value)}
      />
      <TextField
        key={"DL"}
        label={"DL Number"}
        variant="outlined"
        type="text"
        value={formData["DRIVER_LICENSE_NUMBER"] || ""}
        onChange={(e) => handleFieldChange("DRIVER_LICENSE_NUMBER", e.target.value)}
      />

      <TextField
        key={"operationalArea"}
        label={"Operational Area"}
        variant="outlined"
        type="text"
        value={formData["operationalArea"] || ""}
        onChange={(e) => handleFieldChange("operationalArea", e.target.value)}
      />
      <TextField
        key={"status"}
        label={"Status"}
        variant="outlined"
        type="text"
        value={formData["status"] || ""}
        onChange={(e) => handleFieldChange("status", e.target.value)}
      />
      <Button variant="contained" disabled={loading} color="primary" onClick={handleSubmit}>
        {loading ? "Updating..." : "Save Changes"}
      </Button>
    </div>
  );
}

EditRider.propTypes = {
  rider: PropTypes.any,
  trigger: PropTypes.any,
  setTrigger: PropTypes.any,
};

export default EditRider;
