import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";

// Images
import team2 from "assets/images/team-2.jpg";
import { ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear().toString().slice(-4);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export default function data(riders, openDialog) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        Winning : {title}
      </MDTypography>
      <MDTypography variant="caption">Deposit : {description}</MDTypography>
    </MDBox>
  );

  Job.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Rider", accessor: "name", width: "45%", align: "left" },
      { Header: "Contact", accessor: "phone", align: "left" },
      { Header: "Company", accessor: "Company", align: "center" },
      { Header: "Location", accessor: "Location", align: "center" },
      { Header: "Last update", accessor: "last_update", align: "center" },
      { Header: "Location", accessor: "view", align: "center" },
    ],

    rows: riders.map((rider, index) => {
      return {
        name: <Author image={rider.PHOTO} name={rider.NAME} email={rider.CID} />,
        phone: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Phone 1: {rider.CONTACT_1} <br />
            Phone 2 : {rider.CONTACT_2}
          </MDTypography>
        ),
        EMAIL: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {rider.EMAIL}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={rider.status}
              color={rider?.status?.toLowerCase() === "active" ? "success" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),

        Company: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {rider.Company}
          </MDTypography>
        ),

        Location: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            lat: {rider?.location?.latitude} <br />
            lon : {rider?.location?.longitude}
          </MDTypography>
        ),
        last_update: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formatTimestamp(rider.location?.lastSync)}
          </MDTypography>
        ),
        view: (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              window.open(
                `https://www.google.com/maps?q=${rider?.location?.latitude},${rider?.location?.longitude}`,
                "_blank"
              );
            }}
          >
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="blue"
                fontWeight="medium"
              >
                Map
              </MDTypography>
              <ArrowForward marginLeft="10px" />
            </MDBox>
          </span>
        ),
      };
    }),
  };
}
