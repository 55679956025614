import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Stack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Dialog, Icon } from "@mui/material";
import { Link } from "react-router-dom";
import apiHelper from "utils/api";
import { useTheme } from "@mui/material/styles";
import DataTable from "examples/Tables/DataTable";
import useMediaQuery from "@mui/material/useMediaQuery";
import usersTableData from "layouts/admin/components/adminsTable";
import EditCoordinates from "./components/editCoordinates";

function Admins() {
  const [searchField, setSearchField] = useState();
  const [admins, setAdmins] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [admin, setAdmin] = useState();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getAdmins = async () => {
    setFetching(true);
    try {
      const response = await apiHelper.get("admins/get-admins");
      setAdmins(response.admins);
      setFetching(false);
    } catch (e) {
      setFetching(false);

      alert(e.message);
      console.log(e);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (admin) => {
    setAdmins(admin);
    setOpen(true);
  };
  const { columns, rows } = usersTableData(admins, null);

  useEffect(() => {
    getAdmins();
  }, []);
  return (
    <DashboardLayout fetching={fetching}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <EditCoordinates />
      </Dialog>
      <DashboardNavbar setTextField={setSearchField} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                justifyContent="space-between"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Admins
                </MDTypography>
                <Stack direction="row" spacing={2}>
                  <Link to={"/admins/newAdmin"}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;New Admin
                    </MDButton>
                  </Link>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {/* <Pagination
          onChange={(e, value) => setPageInfo((prev) => ({ ...prev, page: value }))}
          count={pageInfo.totalPages}
          page={pageInfo.page}
          variant="outlined"
          style={{ marginTop: 10 }}
        /> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Admins;
