import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useFilePicker } from "use-file-picker";
import MDButton from "components/MDButton";
import QueryBuilder from "layouts/queryBuilder";

// Data
import usersTableData from "layouts/riders/components/ridersTable";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDPagination from "components/MDPagination";
import apiHelper from "utils/api";
import { useEffect, useState } from "react";
import EditRider from "./components/EditRider";
import { RIDERS_CHUNK_DATA } from "utils/chunks";
import useDebounce from "hooks/useDebounce";
import EditRiderTL from "./components/editRiderTL";
import { useLocation, useNavigate } from "react-router-dom";

function Riders() {
  const [riders, setriders] = useState([]);
  const [rider, setRider] = useState();
  const [status, setStatus] = useState("All");
  const [searchField, setSearchField] = useState();
  const [fetching, setFetching] = useState(false);
  const [isQuery, setIsQuery] = useState();
  const [trigger, setTrigger] = useState(false);
  const [isEditTl, setIsEditTl] = useState(false);
  const [companies, setCompanies] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialPageSize = parseInt(queryParams.get("pageSize")) || 10;
  const [pageInfo, setPageInfo] = useState({
    page: initialPage,
    pageSize: initialPageSize,
    totalriders: 0,
    totalPages: 0,
    query: undefined,
  });
  const debouncedQuery = useDebounce(searchField, 300);
  useEffect(() => {
    fetchUsers();
  }, [pageInfo.page, pageInfo.query, trigger]);

  const fetchUsers = async () => {
    setFetching(true);
    try {
      await apiHelper
        .get(`riders/get-riders?page=${pageInfo.page}&query=${encodeURIComponent(pageInfo.query)}`)
        .then((data) => {
          console.log(data.riders);
          setriders(data.riders);
          setFetching(false);
          setPageInfo((info) => ({ ...info, ...data.pagination }));
          navigate(`?page=${pageInfo.page}&pageSize=${pageInfo.pageSize}`, { replace: true });
        });

      const savedCompanies = localStorage.getItem("companies");
      console.log(savedCompanies);

      if (!savedCompanies) {
        const companiesRequest = await apiHelper.get("admins/get-companies");
        localStorage.setItem("companies", companiesRequest?.companies);
        setCompanies(companiesRequest?.companies);
      } else {
        setCompanies(savedCompanies);
      }
    } catch (error) {
      setFetching(false);
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    if (debouncedQuery) {
      fetchSilently(debouncedQuery);
    } else {
      fetchUsers();
    }
  }, [debouncedQuery]);
  const fetchSilently = async (debouncedQuery) => {
    try {
      await apiHelper
        .get(
          `riders/get-riders?page=${pageInfo.page}&query=${encodeURIComponent(
            `NAME=${debouncedQuery}`
          )}`
        )
        .then((data) => {
          setriders(data.riders);
          setPageInfo((info) => ({ ...info, ...data.pagination }));
        });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleClickOpen = (rider) => {
    setIsEditTl(false);
    setIsQuery(false);
    setRider(rider);
    setOpen(true);
  };

  const openTLEditDialog = (rider) => {
    setRider(rider);
    console.log(rider);
    setIsQuery(false);
    setIsEditTl(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleQuerySubmit = (queries) => {
    const queryString = queries.map((query) => `${query.field}=${query.value}`).join("&");
    setPageInfo((info) => ({ ...info, query: queryString }));
  };
  const { openFilePicker, filesContent, loading } = useFilePicker({
    accept: ".csv",
    multiple: false,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      handleBulkImport(plainFiles[0]);
    },
  });
  const handleBulkImport = async (file) => {
    try {
      setFetching(true);
      const response = await apiHelper.upload("riders/import", file);
      setFetching(false);
      alert(response.message);
    } catch (error) {
      setFetching(false);
      console.error("Error uploading file:", error);
      alert(error);
    }
  };
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { columns, rows } = usersTableData(
    riders,
    handleClickOpen,
    openTLEditDialog,
    setPageInfo,
    companies
  );

  return (
    <DashboardLayout fetching={fetching}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"View/Edit Rider"}</DialogTitle>
        {isQuery ? (
          <QueryBuilder
            chunk={RIDERS_CHUNK_DATA}
            handleQuerySubmit={handleQuerySubmit}
          ></QueryBuilder>
        ) : (
          <>
            {isEditTl ? (
              <EditRiderTL
                _id={rider._id}
                prevhub={rider?.hub?.HUB_TL_ID}
                prevtl={rider.TL?.HUB_TL_ID}
              />
            ) : (
              <EditRider trigger={trigger} setTrigger={setTrigger} rider={rider} />
            )}
          </>
        )}
      </Dialog>
      <DashboardNavbar setTextField={setSearchField} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                justifyContent="space-between"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Riders List
                </MDTypography>
                <Stack direction="row" spacing={2}>
                  <MDButton
                    onClick={() => {
                      setIsQuery(true);
                      setOpen(true);
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>query_builder</Icon>
                    &nbsp;Query Builder
                  </MDButton>

                  <MDButton
                    onClick={() => {
                      openFilePicker();
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>upload</Icon>
                    &nbsp;Bulk Upload
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Pagination
          onChange={(e, value) => setPageInfo((prev) => ({ ...prev, page: value }))}
          count={pageInfo.totalPages}
          page={pageInfo.page}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Riders;
