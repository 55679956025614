import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import MDSelect from "components/MDSelect";
import { MDNewInput } from "components/MDNewInput";
import MDTypography from "components/MDTypography";
import apiHelper from "utils/api";
import { useLocation, useParams } from "react-router-dom";

function NewAdmin() {
  const { pathname } = useLocation();
  const [isEdit, setisEdit] = useState(pathname.includes("editAdmin"));
  const { admin } = useParams();
  console.log(admin);
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Password: "",
    HUB_TL_ID: "",
    Areas: "",
    Lat: "",
    Lon: "",
  });
  const [role, setRole] = useState(121);
  const [loading, setLoading] = useState(false);
  const handleFormDataChanges = (key, value) => {
    setFormData((initialData) => ({
      ...initialData,
      [key]: value,
    }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (isEdit) {
      updateAdmin();
      return;
    }
    try {
      const data = {
        ...formData,
        role,
        areas: formData.Areas.split(","),
        coordinates: { lat: formData.Lat, lon: formData.Lon },
      };
      const response = await apiHelper.post("admins/register", data);
      console.log(response);
      alert(response.message);
      console.log(response);
      setLoading(false);
    } catch (e) {
      console.log(e);
      alert(e);
      setLoading(false);
    }
  };

  const updateAdmin = async () => {
    setLoading(true);
    try {
      const data = {
        name: formData.Name,
        email: formData.Email,
        phone: formData.Phone,
        password: formData.Password,
        _id: admin,
        areas: formData.Areas.split(","),
        coordinates: { lat: formData.Lat, lon: formData.Lon },
      };
      const response = await apiHelper.post("admins/update-admin", { admin: { ...data } });
      if (response) {
        setFormData({
          Name: response.admin.name,
          Email: response.admin.email,
          Password: response.admin.password,
          Phone: response.admin.phone,
          HUB_TL_ID: response.admin.HUB_TL_ID,
          Areas: response.admin.areas.join(","),
          Lat: response.admin?.coordinates?.lat,
          Lon: response.admin?.coordinates?.lon,
        });
      }
      alert(response.message);
      setLoading(false);
    } catch (e) {
      console.log(e);
      alert(e);
      setLoading(false);
    }
  };
  const fetchAdmin = async () => {
    try {
      setLoading(true);
      const response = await apiHelper.get("admins/get-admin/" + admin);
      if (response) {
        setFormData({
          Name: response.admin.name,
          Email: response.admin.email,
          Password: response.admin.password,
          Phone: response.admin.phone,
          HUB_TL_ID: response.admin.HUB_TL_ID,
          Areas: response.admin.areas.join(","),
          Lat: response.admin?.coordinates?.lat,
          Lon: response.admin?.coordinates?.lon,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error");
    }
  };

  useEffect(() => {
    if (isEdit) fetchAdmin();
  }, [admin]);
  return (
    <DashboardLayout fetching={loading}>
      <DashboardNavbar />
      <Grid
        container
        paddingTop={2}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={3}
        justifyContent={"center"}
      >
        {Object.keys(formData).map((key) => {
          return (
            <Grid key={key} item>
              <MDTypography variant="button" fontWeight="medium" color="text">
                {key}
              </MDTypography>
              <MDNewInput
                placeholder={key}
                key={key}
                style={{ width: "100%" }}
                value={formData[key]}
                onChange={(e) => handleFormDataChanges(key, e.target.value)}
              />
            </Grid>
          );
        })}
        <Grid style={{ display: "flex" }} item>
          <MDBox style={{ display: "flex" }} flex flexDirection="column" justifyContent="flex-end">
            <MDTypography variant="button" fontWeight="medium" color="text">
              Admin Type
            </MDTypography>
            <MDSelect
              options={[
                { title: "HUB", value: "121" },
                { title: "TL", value: "131" },
                { title: "ADMIN", value: "111" },
              ]}
              value={role}
              width={"100%"}
              setValue={setRole}
            />
          </MDBox>
        </Grid>
      </Grid>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <SubmitButton onClick={handleSubmit} sx={{ ml: 1, mt: 5 }} type="submit">
          Submit
        </SubmitButton>
      </MDBox>
    </DashboardLayout>
  );
}
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
  900: "#003A75",
};

const SubmitButton = styled("button")(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: ${blue[500]};
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid ${blue[500]};
    box-shadow: 0 2px 1px ${
      theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.5)" : "rgba(45, 45, 60, 0.2)"
    }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};
  
    &:hover {
      background-color: ${blue[600]};
    }
  
    &:active {
      background-color: ${blue[700]};
      box-shadow: none;
    }
  
    &:focus-visible {
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[300] : blue[200]};
      outline: none;
    }
  `
);

export default NewAdmin;
