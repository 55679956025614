import React, { useState, useEffect } from "react";
import MapComponent from "./components/MapComponent";
import apiHelper from "utils/api";
import { Grid, Pagination } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDSelect from "components/MDSelect";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import useDebounce from "hooks/useDebounce";
import DataTable from "examples/Tables/DataTable";
import usersTableData from "layouts/locations/components/userTable";

const App = () => {
  const [userLocations, setUserLocations] = useState([]);
  const [rawLocations, setRawLocations] = useState({});
  const [trackedUser, setTrackedUser] = useState(null);
  const [viewType, setViewType] = useState(0);
  const [searchField, setSearchField] = useState();
  const [riders, setriders] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    totalriders: 0,
    totalPages: 0,
    query: undefined,
  });
  const debouncedQuery = useDebounce(searchField, 300);
  useEffect(() => {
    fetchUsers();
  }, [pageInfo.page, pageInfo.query, rawLocations]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await apiHelper.get(`admin/riders/getLiveLocation`).then((data) => {
          console.log(data.locationData);
          const locations = Object.keys(data.locationData).map((key) => ({
            mobile: key,
            ...data.locationData[key],
          }));
          setRawLocations(data.locationData);
          setUserLocations(locations);
        });
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchData();
  }, []);
  const fetchUsers = async () => {
    setFetching(true);
    try {
      await apiHelper
        .get(`riders/get-riders?page=${pageInfo.page}&query=${encodeURIComponent(pageInfo.query)}`)
        .then((data) => {
          let ridersData = mapLocationDataToRiders(data.riders, rawLocations);
          setriders(ridersData);
          setFetching(false);
          setPageInfo((info) => ({ ...info, ...data.pagination }));
        });
    } catch (error) {
      setFetching(false);
      console.error("Error fetching users:", error);
    }
  };
  const fetchSilently = async (debouncedQuery) => {
    try {
      await apiHelper
        .get(
          `riders/get-riders?page=${pageInfo.page}&query=${encodeURIComponent(
            `NAME=${debouncedQuery}`
          )}`
        )
        .then((data) => {
          let ridersData = mapLocationDataToRiders(data.riders, rawLocations);
          setriders(ridersData);
          setPageInfo((info) => ({ ...info, ...data.pagination }));
        });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const mapLocationDataToRiders = (riders, locationData) => {
    return riders.map((rider) => {
      const contact1Formatted = formatPhoneNumber(rider.CONTACT_1);
      const contact2Formatted = formatPhoneNumber(rider.CONTACT_2);

      if (locationData[contact1Formatted]) {
        rider.location = locationData[contact1Formatted];
      } else if (locationData[contact2Formatted]) {
        rider.location = locationData[contact2Formatted];
      } else {
        rider.location = null;
      }
      console.log(locationData);
      return rider;
    });
  };
  const formatPhoneNumber = (phoneNumber) => {
    return "+91" + phoneNumber.trim();
  };
  useEffect(() => {
    if (debouncedQuery) {
      fetchSilently(debouncedQuery);
    } else {
      fetchUsers();
    }
  }, [debouncedQuery]);
  const { columns, rows } = usersTableData(riders, null);

  return (
    <DashboardLayout fetching={fetching} className="App">
      <DashboardNavbar setTextField={setSearchField} />
      <MDSelect
        options={[
          { title: "Clustered", value: 0 },
          { title: "Rider Wise", value: 1 },
        ]}
        setValue={setViewType}
      />
      <MDBox height={10} />
      {viewType === 1 && (
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      )}
      {userLocations.length != 0 && viewType === 0 && (
        <MapComponent userLocations={userLocations} trackedUser={trackedUser} />
      )}
      <MDBox height={10} />
      {viewType === 1 && (
        <Pagination
          onChange={(e, value) => setPageInfo((prev) => ({ ...prev, page: value }))}
          count={pageInfo.totalPages}
          page={pageInfo.page}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      )}
    </DashboardLayout>
  );
};

export default App;
