import axios from "axios";

const BASE_URL = "https://api.etmbikes.com/";
const AUTH_TOKEN_KEY = "user";

const apiHelper = {
  get: async (url, headers = {}) => {
    try {
      const authToken = localStorage.getItem(AUTH_TOKEN_KEY);

      const response = await fetch(`${BASE_URL}${url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ? `${authToken}` : "",
          ...headers,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      return response.json();
    } catch (e) {
      console.error("API Request Error:", e);
      throw e;
    }
  },
  post: async (url, data, headers = {}) => {
    try {
      const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
      console.log(authToken);
      const response = await fetch(`${BASE_URL}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ? `${authToken}` : "",
          ...headers,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        return response.json().then((json) => {
          throw new Error(json.message);
        });
      }

      return response.json();
    } catch (error) {
      console.error("API Request Error:", error);
      throw error;
    }
  },
  upload: async (url, file, headers = {}) => {
    try {
      const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(`${BASE_URL}${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authToken ? authToken : "",
          ...headers,
        },
      });
      console.log(response);
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  },
};

export default apiHelper;
