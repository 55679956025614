import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon, Tab, Tabs } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useFilePicker } from "use-file-picker";
import MDButton from "components/MDButton";
import QueryBuilder from "layouts/queryBuilder";

// Data
import usersTableData from "layouts/onboardings/components/onboardingsTable";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDPagination from "components/MDPagination";
import apiHelper from "utils/api";
import { useEffect, useState } from "react";
import EditRider from "./components/EditRider";
import { RIDERS_CHUNK_DATA } from "utils/chunks";
function Onboardings() {
  const [riders, setriders] = useState([]);
  const [rider, setRider] = useState();
  const [isList, setIsList] = useState();
  const [isHistory, setIsHistory] = useState();
  const [searchField, setSearchField] = useState();
  const [fetching, setFetching] = useState(false);
  const [isQuery, setIsQuery] = useState();
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    totalriders: 0,
    totalPages: 0,
    query: undefined,
  });
  const fetchAllOnboardings = async () => {
    setFetching(true);
    try {
      await apiHelper.get(`riders/get-onboardings`).then((data) => {
        console.log(data.onboardings);
        setriders(data.onboardings);
        setFetching(false);
      });
    } catch (error) {
      setFetching(false);
      console.error("Error fetching users:", error);
    }
  };

  const fetchAllPending = async () => {
    setFetching(true);
    try {
      await apiHelper.get(`riders/get-pending-onboardings`).then((data) => {
        console.log(data.onboardings);
        setriders(data.onboardings);
        setFetching(false);
      });
    } catch (error) {
      setFetching(false);
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchAllPending();
  }, []);

  const handleClickOpen = (vehicle, isList, isHistory) => {
    setIsQuery(false);
    setRider(vehicle);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSetTabValue = (event, newValue) => {
    if (newValue === 0) {
      fetchAllPending();
    } else {
      fetchAllOnboardings();
    }
    setTabValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { columns, rows } = usersTableData(riders, handleClickOpen);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  return (
    <DashboardLayout fetching={fetching}>
      <DashboardNavbar setTextField={setSearchField} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                justifyContent="space-between"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Onboardings List
                </MDTypography>
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Pending"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25, me: 1 }}>
                        access_time
                      </Icon>
                    }
                  />
                  <Tab
                    label="All"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        adjust
                      </Icon>
                    }
                  />
                </Tabs>
                {/* <MDButton
                    onClick={() => {
                      setIsQuery(true);
                      setOpen(true);
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>query_builder</Icon>
                    &nbsp;Query Builder
                  </MDButton> */}

                {/* <MDButton
                    onClick={() => {
                      openFilePicker();
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>upload</Icon>
                    &nbsp;Bulk Upload
                  </MDButton> */}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Pagination
          onChange={(e, value) => setPageInfo((prev) => ({ ...prev, page: value }))}
          count={pageInfo.totalPages}
          page={pageInfo.page}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Onboardings;
