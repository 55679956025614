import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { useMaterialUIController, setProfile, setOpenProfile } from "context";
import PropTypes from "prop-types";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const MapComponent = ({ userLocations, trackedUser }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    openProfile,
    openConfigurator,
    profile,
  } = controller;

  let center = trackedUser
    ? { lat: trackedUser.lat, lng: trackedUser.lng }
    : { lat: 25.6201893, lng: 85.0566799 };

  const handleMarkerClick = (user) => {
    setProfile(dispatch, user);
    setOpenProfile(dispatch, true);
  };

  const MapLayout = useMemo(() => {
    return (
      <LoadScript googleMapsApiKey="AIzaSyCLBbA2uOYEeJ-dOC2klZDjK6Yvg3CDUqU">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
          {userLocations.map((user, index) => (
            <Marker
              key={index}
              position={{ lat: user.latitude, lng: user.longitude }}
              onClick={() => handleMarkerClick(user)}
              icon={{
                url: "https://img.icons8.com/fluency/48/place-marker.png",
              }}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    );
  }, []);

  return <div>{MapLayout}</div>;
};

MapComponent.propTypes = {
  userLocations: PropTypes.any,
  trackedUser: PropTypes.any,
};

export default MapComponent;
