import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";

function QueryBuilder({ chunk, handleQuerySubmit }) {
  const fields = Object.keys(chunk).map((key) => key);

  const [queries, setQueries] = useState([{ field: "", value: "" }]);

  const handleChange = (index, field, value) => {
    const newQueries = [...queries];
    newQueries[index] = { field, value };
    setQueries(newQueries);
  };

  const addQuery = () => {
    setQueries([...queries, { field: "", value: "" }]);
  };

  const removeQuery = (index) => {
    const newQueries = [...queries];
    newQueries.splice(index, 1);
    setQueries(newQueries);
  };

  const handleSubmit = () => {
    const queryObject = queries.reduce((acc, query) => {
      if (query.field && query.value) {
        acc[query.field] = query.value;
      }
      return acc;
    }, {});
    handleQuerySubmit(queries);
  };

  return (
    <div style={{ padding: 10 }}>
      {queries.map((query, index) => (
        <FormControl
          key={index}
          style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          variant="filled"
          sx={{ m: 1, minWidth: 150 }}
        >
          <Select
            value={query.field}
            onChange={(e) => handleChange(index, e.target.value, query.value)}
            style={{ width: "50%", height: 48 }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {fields.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
          <TextField
            value={query.value}
            onChange={(e) => handleChange(index, query.field, e.target.value)}
            style={{ height: 40, marginLeft: 10 }}
            id="filled-basic"
            label="Query"
            variant="filled"
          />
          <MDButton
            onClick={() => removeQuery(index)}
            variant="gradient"
            color="error"
            alignItems="center"
            justifycontent="center"
            style={{ margin: 10 }}
          >
            <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
          </MDButton>
          <MDButton
            onClick={addQuery}
            variant="gradient"
            color="dark"
            alignItems="center"
            justifycontent="center"
            style={{ margin: 10, width: 20 }}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          </MDButton>
        </FormControl>
      ))}
      <MDButton
        onClick={handleSubmit}
        variant="gradient"
        color="dark"
        alignItems="center"
        justifycontent="center"
        style={{ margin: 10 }}
      >
        <Icon sx={{ fontWeight: "bold" }}>check</Icon>
        &nbsp; Submit
      </MDButton>
    </div>
  );
}

QueryBuilder.propTypes = {
  chunk: PropTypes.any,
  handleQuerySubmit: PropTypes.any,
};

export default QueryBuilder;
