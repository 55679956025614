import apiHelper from "utils/api";

const { useEffect, useState } = require("react");

function useVehicle() {
  const [vehicles, setVehicles] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [vehicle, setVehicle] = useState();

  const fetchVehicles = async () => {
    setFetching(true);
    try {
      await apiHelper.get(`vehicles/get-vehicles?page=${1}&limit=10000`).then((data) => {
        console.log(data.vehicles);
        setVehicles(data.vehicles.map((vehicle, index) => vehicle.vehicle_id));
        setVehicle(data.vehicles[0].vehicle_id);
        setFetching(false);
      });
    } catch (error) {
      setFetching(false);

      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return { vehicle, setVehicle, vehicles, fetching };
}

export default useVehicle;
