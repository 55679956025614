import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import dayjs from "dayjs";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Icon } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useFilePicker } from "use-file-picker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
// Data
import reportsTableData from "layouts/reports/components/reportsTable";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDPagination from "components/MDPagination";
import apiHelper from "utils/api";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import * as XLSX from "xlsx";
import { calculateDuration, formatLocalTime } from "./components/reportsTable";

function Reports() {
  const today = dayjs().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [from, setFrom] = useState(dayjs());
  const [to, setTo] = useState(dayjs());
  const handleClickOpen = () => {
    try {
    } catch (error) {}
  };
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    totalVehicles: 0,
    totalPages: 0,
    query: undefined,
  });
  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        const response = await apiHelper.get(
          `admin/reports/generate-reports?startDate=${startDate}&endDate=${endDate}`
        );
        setReports(response.reports);
        setLoading(false);
      } catch (e) {
        console.log("Error fetching reports: " + e);
        setLoading(false);
      }
    };
    fetchReports();
  }, [startDate, endDate]);
  const { columns, rows } = reportsTableData(reports, handleClickOpen);

  const exportToExcel = () => {
    const data = reports.map((report) => ({
      Rider: report.rider.NAME,
      RiderCID: report.rider?.CID?.trim(),
      Date: report.date,
      "2W/3W": report.rider?.CID?.toLowerCase()?.includes("3w")
        ? "3W"
        : report.rider?.CID?.toLowerCase()?.includes("2w")
        ? "2W"
        : report.rider?.CID,
      "Punch In": report.punchedIn ? formatLocalTime(report.punchInTime) : "Not Punched in",
      "Punch Out": report.punchedOut ? formatLocalTime(report.punchOutTime) : "Not Punched out",
      Company: report.rider.Company,
      "Company Id": report.rider.Company_ID?.toUpperCase(),
      Area: report.rider.operationalArea,
      Orders: report?.orders ? report.orders : "N/A",
      "Cash Collected": report?.collection ? report.collection : "N/A",
      "Cash Deposited": report?.deposit ? report.deposit : "N/A",
      Duration: calculateDuration(report.punchInTime, report.punchOutTime),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

    XLSX.writeFile(workbook, "reports.xlsx");
  };

  return (
    <DashboardLayout fetching={loading}>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="whit"
                borderRadius="lg"
                coloredShadow="info"
                justifyContent="space-between"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="info">
                  Reports
                </MDTypography>
                <Stack direction="row" spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DateField
                        label="From"
                        value={from}
                        onChange={(newValue) => {
                          setFrom(newValue);
                          setStartDate(newValue.format("YYYY-MM-DD"));
                        }}
                      />
                    </DemoContainer>
                    <DemoContainer components={["DatePicker"]}>
                      <DateField
                        label="To"
                        value={to}
                        onChange={(newValue) => {
                          setTo(newValue);
                          setEndDate(newValue.format("YYYY-MM-DD"));
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <MDButton
                    onClick={() => {
                      exportToExcel();
                    }}
                    variant="gradient"
                    color="dark"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                    &nbsp;Generate Report
                  </MDButton>
                </Stack>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Pagination
          onChange={(e, value) => setPageInfo((prev) => ({ ...prev, page: value }))}
          count={pageInfo.totalPages}
          page={pageInfo.page}
          variant="outlined"
          style={{ marginTop: 10 }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Reports;
