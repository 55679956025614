import { useState, useEffect } from "react";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
  setProfile,
  setOpenProfile,
} from "context";
import apiHelper from "utils/api";
import { ClipLoader, ClockLoader } from "react-spinners";
import { formatTimestamp } from "layouts/locations/components/userTable";

function Profile() {
  const [rider, setRider] = useState({});
  const [fetching, setFetching] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { fixedNavbar, darkMode, openProfile, openConfigurator, profile } = controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];

  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    window.addEventListener("resize", handleDisabled);

    handleDisabled();

    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenProfile(dispatch, false);
  const fetchSilently = async () => {
    setFetching(true);
    try {
      await apiHelper
        .get(`admin/riders/getriderprofile?mobile=${profile?.mobile?.slice(-10)}`)
        .then((data) => {
          setRider(data.rider);
        });
      setFetching(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchSilently();
  }, [profile]);
  return (
    <ConfiguratorRoot variant="permanent" ownerState={openProfile}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Rider information</MDTypography>
          <MDTypography variant="body2" color="text">
            {rider?.NAME}
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      {fetching && (
        <div
          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
          <ClockLoader loading />
        </div>
      )}

      {!fetching && (
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={2}
          pb={0.5}
          px={3}
        >
          <img
            width={150}
            height={150}
            style={{ borderRadius: 100 }}
            src={rider?.PHOTO}
            alt="rider_image"
          />
          <MDTypography variant="h6">CID :{rider?.CID}</MDTypography>
          <MDTypography variant="h6">Company : {rider?.Company}</MDTypography>
          <MDTypography variant="h6">
            Last Location update : {formatTimestamp(profile?.lastSync)}
          </MDTypography>
        </MDBox>
      )}

      <Divider />
    </ConfiguratorRoot>
  );
}

export default Profile;
